import React from "react"
import {Link} from "gatsby"

import SEO from "../components/seo"

import styles from "./404.module.css"

export default () => (
  <div className={styles.container}>
    <SEO 
        title="This is not the page you are looking for." 
        description="Are you looking for https://terencelucasyap.com instead?"
        url="https://terencelucasyap.com"
        image="https://media.giphy.com/media/l2JJKs3I69qfaQleE/source.gif"
      />
    <img src="https://media.giphy.com/media/l2JJKs3I69qfaQleE/source.gif" alt="404" className={styles.error}/>
    <h1>This is not the page you are looking for.</h1>
    <p>
      <Link to={`/`}>Go Home</Link>
    </p>
  </div>
)